import React,{ createContext } from 'react';
import useTokenCollab from "../hooks/useTokenCollab"

export const TokenCollabContext = createContext(null);

const TokenCollabProvider = ({children}) =>{
    const {
        uploadFileToIPFSCollab,
        isWorking, mintTokenCollab, fetchTokensCollabs, collabsTokens, isTransferring, transferTokenCollab, getAllTokenCollab, isLoadingTokenCollab} = useTokenCollab();

    return (
        <TokenCollabContext.Provider value={{
            uploadFileToIPFSCollab,
            isWorking, mintTokenCollab, fetchTokensCollabs, collabsTokens, isTransferring, transferTokenCollab, getAllTokenCollab, isLoadingTokenCollab}}>
            {children}
        </TokenCollabContext.Provider>
    );
}

export default TokenCollabProvider;

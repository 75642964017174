import { BrowserRouter, Routes, Route } from "react-router-dom";
import DetailProduct from "../pages/DetailProduct";
import MarketPlace from "../pages/MarketPlace";
import Me from "../pages/Me";
import Create from "../pages/Create";
import Transfer from "../pages/Transfer";
import Admin from "../pages/Admin";
import { DAppProvider } from "@usedapp/core";
import MinterRoute from "./MinterRoutes"
import AdminRoute from "./AdminRoutes"
import {useContext, useEffect} from "react";
import {DataContext} from "../providers/MainProvider";

const RoutesRoot = () => {
    const { fetchOnSales} = useContext(DataContext);
    useEffect(async () => {
        const loadData = async () => {
            // TODO: fetch data from API
            await fetchOnSales()
        };

        await loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <BrowserRouter>

      <Routes>
          <Route path="/" index element={ <DAppProvider config={{}}><MarketPlace /></DAppProvider>} />
          <Route path="marketplace" index element={<DAppProvider config={{}}><MarketPlace /> </DAppProvider>} />
          <Route path="marketplace/:idProduct" element={<DAppProvider config={{}}><DetailProduct /> </DAppProvider>} />
          <Route path="me" element={<DAppProvider config={{}}><Me /></DAppProvider>} />
          <Route path="create" element={<DAppProvider config={{}}> <MinterRoute> <Create /></MinterRoute></DAppProvider>} />
          <Route path="transfer" element={<DAppProvider config={{}}> <AdminRoute> <Transfer /> </AdminRoute></DAppProvider>} />
          <Route path="admin" element={<DAppProvider config={{}}> <AdminRoute> <Admin />  </AdminRoute> </DAppProvider>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesRoot;

import React, {useContext, useEffect} from "react";
import LandingPageLayout from "../../components/LayoutMain";
import AppContainer from "../../components/AppContainer";
import {AdminTable} from "../../components/Table";
import {Select, Input, Button} from "../../components/Base"
import  {useWorkingIndicator} from "../../hooks/useBooleanToggler";
import useForm from "../../hooks/useForm";
import {OwnerContext} from "../../providers/OwnerProvider";


const Admin = () => {
    const {isWorking: savingRole, startWork: startSaveRole, finishWork: finishSaveRole} = useWorkingIndicator();
    const {isWorking: removingRole, startWork: startRemovingRole, finishWork: finishRemovingRole} = useWorkingIndicator();
    const {isWorking: fetchingRoles, startWork: startFetchRoles, finishWork: finishFetchingRole} = useWorkingIndicator();
    const {data, handleChange, cleanData} = useForm({address: "", role: ""});
    const {addMinter, remove, addAdmin, accounts, fetchRoles} = useContext(OwnerContext);


    useEffect(async ()=> {
        await fetchRolesData();
        console.log("aquiii")
    }, [])

    const removeRole = async(address = {}) => {
        startRemovingRole()
        if(address.role && address.role.length > 0){
            await remove(address)
        }
        finishRemovingRole()
    }



    const fetchRolesData = () => {
        console.log("aquii")
        startFetchRoles();
        fetchRoles();
        finishFetchingRole();
    }

    const saveRole = async () => {
        if(!data.address){
            alert("Please enter a valid address")
            return
        } else {
             startSaveRole()
            if(data.role === "Admin"){
               await addAdmin(data.address)
            } else if(data.role === "Minter"){
                await addMinter(data.address)
            } else {
                alert("pick a role")
            }
            cleanData();
            finishSaveRole()
        }
    }
    console.log("savingRole", savingRole)

    const accountFiltered = accounts.filter(account => account.role !== null);


    return (
        <LandingPageLayout>
            <AppContainer className="my-8">
                <div>
                    Admin
                </div>
                <AdminTable data={accountFiltered} loading={fetchingRoles} removeRole={removeRole} removing={removingRole}/>
                <div className="w-full max-w-lg pt-5">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <Input label="address" handleChange={handleChange} feedback={true} value={data["address"]} name="address"/>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <Select data={data["role"]} onChange={handleChange}/>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <Button loading={savingRole} onClick={saveRole} title={savingRole? "Saving Role...": "Save Role"}/>
                        </div>
                    </div>
                </div>
            </AppContainer>
        </LandingPageLayout>
    );
};

export default Admin;

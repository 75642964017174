
const TextArea = ({value="", name = "", type="text", label = "", handleChange, hide= false, feedback=true})=> {
    return (
        <>
            {!hide &&  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor={name}>
                {label}
            </label>}
            <textarea value={value} onChange={(e)=> handleChange(e)}
                   className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                   id={name} type={type} placeholder={label} name={name} rows="3"/>
            {feedback &&   <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
        </>
    )
}

export default TextArea

export const stateAddress = "0xeeCd2F1bD6F90f15dfb19A78CeA41cb99c992B04";
export const nftAddress = "0x355C6239A0C69e88fac2cc6bEddFe5eb06eECbe3";
export const nft1155Adress = "0x9d60Ab63f1F8aCFBa0E63bbC2eC8f9a6B1fA296F";
export const nftCollabAddress = "0x28E27018c09f83Bec8C25BF8B0BAcE854e1F21C8";
export const fileType = "video/*"
export const posterFileType = "image/*"
export const GRAPH_URL = "https://api.thegraph.com/subgraphs/name/guillermocalderon2021/shoes-token";
export const BACKUP_GRAPH = "https://api.thegraph.com/subgraphs/name/guillermocalderon2021/shoes-token"
export const network = "mainnet"
export const rpcEndpoint = "https://polygon-mainnet.g.alchemy.com/v2/yG0dc0Xi8cWeN49CncCrop7zrvUckpb7"
export const newRpcEndpoint = "https://polygon-mainnet.g.alchemy.com/v2/yG0dc0Xi8cWeN49CncCrop7zrvUckpb7"

export const SCREENS = Object.freeze({
    TOKEN: "token",
    TOKEN_COLLAB: "tokenCollab",
    MARKET: "market",
    MULTIMARKET: "N MARKET"
})

export const mumbaiParams = {
    chainId: '0x13881',
    chainName: 'Mumbai',
    nativeCurrency: {
        name: 'Matic',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
}
export const mainNetParamsMatic = {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
        name: 'Matic',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
}

export const binanceNetwork = {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
        name: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com']
}

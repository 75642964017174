import React, {useState} from 'react';
import {File, Button} from "../Base";

const CreateMultiMarketItem = ({execute = ()=> {}, isWorking = false, title = "Create N Item"}) => {
    const [files, setFiles] = useState({});

    async function onChange(e) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setFiles(e.target.result);
        };
    }

    return (
        <div className="flex flex-wrap -mx-3 mb-2 ml-5 mt-5 gap-4">
            <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <File accept={"application/json"} hide={false}
                      label={"Upload your Json"} name={"file"} type="file"
                      handleChange={onChange}
                />
            </div>

            <div className=" w-1/2 md:w-1/3 px-3 mb-6 md:mb-0">
                <Button loading={isWorking}  title={title} disabled={isWorking} onClick={()=> execute(files)}/>
            </div>
        </div>
    )
}

export default CreateMultiMarketItem

import React, { useEffect, useState, useContext } from "react";
import LandingPageLayout from "../../components/LayoutMain";
import AppContainer from "../../components/AppContainer";
import useBooleanToggler, {
  useWorkingIndicator,
} from "../../hooks/useBooleanToggler";
import ImageIcon, { ICONS } from "../../components/ImageIcon";
import Badge from "../../components/Badge";
import CardList from "../../components/CardList";
import DropdownButton from "../../components/DropdownButton";
import FilterShoes from "./FilterShoes";
import { DataContext } from "../../providers/MainProvider";

const filterOptions = ["LATEST", "NEW", "OTHER"];

const MarketPlace = () => {
  const { isToggled: isToggleFilter, toggle: toggleFilter } =
    useBooleanToggler();

  const { nfts: products, isLoading: isWorking } = useContext(DataContext);

  const {
    isToggled: showSort,
    reToggle: openSort,
    unToggle: closeSort,
  } = useBooleanToggler();
  // const { isWorking, startWork, finishWork } = useWorkingIndicator({
  //   initialValue: true,
  // });

  // state filter
  const [filter, setFilter] = useState({
    sort: filterOptions[0],
    models: [],
    menSizes: [],
    womanSizes: [],
    kidSizes: [],
  });

  const handlerClearFilter = () => {
    setFilter({
      sort: filterOptions[0],
      models: [],
      menSizes: [],
      womanSizes: [],
      kidSizes: [],
    });
  };

  const handlerChangeFilter = (filterNew) => {
    setFilter({
      ...filter,
      ...filterNew,
    });
  };



  const handleSort = (sort) => {
    setFilter({
      ...filter,
      sort,
    });
    closeSort();
  };


  return (
    <LandingPageLayout>
      <AppContainer className="my-8">
        <div className="flex justify-between">
          {/* <div className="flex flex-col md:flex-row justify-between items-center">
            <div
              onClick={toggleFilter}
              className="flex gap-3  flex-wrap items-center cursor-pointer"
            >
              <ImageIcon nameIcon={ICONS.filterIcon} />
              <span className="opacity-50 text-lg">FILTER</span>
              {products?.length > 0 && <Badge label={products.length} />}
            </div>

            <div
              className="text-lg cursor-pointer md:ml-[70px]"
              onClick={handlerClearFilter}
            >
              CLEAR
            </div>
          </div> */}

          {/* <div>
            <DropdownButton
              className="top-11 p-5 w-32"
              classNameArrow="top-9 right-8"
              isActive={showSort}
              onOpen={!showSort ? openSort : closeSort}
              onClose={closeSort}
              customContent={
                <div className="flex flex-col divide-y gap-3">
                  {filterOptions.map((option, index) => (
                    <div
                      key={index}
                      className={`${
                        index !== 0 && "pt-3"
                        } opacity-50 cursor-pointer`}
                      onClick={() => handleSort(option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              }
            >
              <div className="flex items-center gap-2 select-none">
                <span className="opacity-50 text-lg">SORT: {filter.sort}</span>
                <ImageIcon
                  className={`transition-all duration-500 ${
                    showSort ? "rotate-180" : ""
                    }`}
                  nameIcon={ICONS.arrowDown}
                />
              </div>
            </DropdownButton>
          </div> */}

        </div>
        <div className="flex gap-14">
          {isToggleFilter && (
            <div
              className="bg-white transition-all duration-500
                fixed w-screen h-screen top-0 left-0 right-0 z-10 overflow-y-scroll p-2 pt-0
                md:bg-transparent  md:w-[288px] md:relative md:h-auto
              "
            >
              <div className="top-1 right-2 fixed flex justify-end md:hidden">
                <div
                  className="p-4 cursor-pointer text-2xl opacity-50"
                  onClick={toggleFilter}
                >
                  x
                </div>
              </div>
              <FilterShoes
                filter={filter}
                onChangeFilter={handlerChangeFilter}
              />
            </div>
          )}
          <div className="flex-1 transition-all duration-500 mt-1">
            {isWorking && (
              <div className="text-center opacity-50 text-lg">Loading...</div>
            )}

            <CardList products={products} isFilterActive={isToggleFilter} />
          </div>
        </div>
      </AppContainer>
    </LandingPageLayout>
  );
};

export default MarketPlace;

import { useState, useContext } from "react";
import { hasRequiredKeys, buildOpenSeaMetadata } from "../helpers";
import { DataContext } from "../providers/MainProvider";

const defaultValues = { listingId: '', title: '', description: '', size: "", styleId: '', manufacturer: "", model: "", nickName: "", colorWay: "", unLockable: [] }
const useForm = (defaultValue = defaultValues) => {
    const [data, setData] = useState(defaultValue);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [uploadingPoster, setUploadingPoster] = useState(false);
    const { mint, generateURI, uploadFileToIPFS, isWorking } = useContext(DataContext)

    const handleChange = (e) => {
        let payload = {}
        payload = {
            ...data, [e.target.id]: e.target.value
        }
        setData(payload);
    }


    const sendFile = async (file) => {
        setUploadingFile(true)
        //console.log("file: " + JSON.stringify(file))
        const url = await uploadFileToIPFS(file);
        console.log("url", url);
        setData({ ...data, animation_url: url });
        setUploadingFile(false)
    }

    const sendPoster = async (file) => {
        setUploadingPoster(true)
        console.log("file: " + JSON.stringify(file))
        const url = await uploadFileToIPFS(file);
        console.log("url");
        console.log(url);
        setData({ ...data, image: url });
        setUploadingPoster(false)
    }

    const uploadUnlockableContent = async (file) => {
        console.log(file.type)
        const url = await uploadFileToIPFS(file);
        const { unLockable = [] } = data;
        const content = unLockable;
        content.push({ url, type: file.type });
        setData({ ...data, unLockable: content });

    }

    const onSubmit = async () => {
        if (hasRequiredKeys(data)) {
            if (!data.listingId || !Number.isInteger(parseInt(data.listingId))) {
                window.alert("No valid listingId")
            } else {
                if (!(/^[0-9]*\.?[0-9]*$/.test(data.size) && data.size !== '.')) {
                    window.alert("No valid Size")
                }
                const rebuiltData = buildOpenSeaMetadata(data);
                await mint({ ...rebuiltData });
                cleanData();
            }
        } else {
            window.alert("you should add all the fields")
        }
    }

    const onGenerateURI = async () => {
        if (hasRequiredKeys(data)) {
            if (!data.listingId || !Number.isInteger(parseInt(data.listingId))) {
                window.alert("No valid listingId")
            } else {
                if (!(/^[0-9]*\.?[0-9]*$/.test(data.size) && data.size !== '.')) {
                    window.alert("No valid Size")
                }
                const rebuiltData = buildOpenSeaMetadata(data);
                await generateURI({ ...rebuiltData });
                cleanData();
            }
        } else {
            window.alert("you should add all the fields")
        }
    }

    const setAllData = (data) => {
        setData(data);
    }

    const cleanData = () => {
        setData(defaultValues)
    }

    return {
        data,
        handleChange,
        sendFile,
        onSubmit,
        onGenerateURI,
        cleanData,
        isWorking,
        uploadingFile,
        setAllData,
        uploadingPoster,
        sendPoster,
        uploadUnlockableContent
    };
};

export default useForm;

import React from 'react';
import {SCREENS} from "../../../config";
import BodyTable from "./Body"

const TransferTable = ({headers, data = [], loading = false, screen = SCREENS.TOKEN}) => {
    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 white:bg-gray-700 dark:text-gray-400">
                <tr>
                    {
                        headers.map((header)=>(
                            <>
                                <th scope="col" className="px-6 py-3 text-black">
                                    {header}
                                </th>
                            </>
                        ))
                    }
                </tr>
                </thead>
                {
                    loading? <div>Loading...</div>:
                        <tbody>
                        {
                            data.map((item, index)=>(
                             <BodyTable item={item} screen={screen}/>
                            ))
                        }


                        </tbody>
                }
            </table>
        </div>
    )
}


export default TransferTable

import React, {useContext, useEffect, useState} from "react";
import LandingPageLayout from "../../components/LayoutMain";
import AppContainer from "../../components/AppContainer";
import {SCREENS} from "../../config";
import RadioButton from "../../components/Base/RadioButton";
import {TransferTable} from "../../components/Table";
import {DataContext} from "../../providers/MainProvider";
import {useWorkingIndicator} from "../../hooks/useBooleanToggler";
import {CreateMultiMarketItem} from "../../components/Forms";

const ownerHeaders = [
    "Name", "Upload File", "Image", "Quantity", "Transfer"
]
const Transfer = () => {
    const {isWorking: fetchingData, startWork: startFetchData, finishWork: finishFetchData} = useWorkingIndicator();
    const {isWorking, startWork, finishWork} = useWorkingIndicator();
    const [screen, setScreen] = useState(SCREENS.MARKET)

    const {nfts,tokenCollabs, tokens, getAll, transferMultiNFT} = useContext(DataContext);
    useEffect(async () => {
      await fetchItems();
        // eslint-disable-next-line
    },[])


    const fetchItems = async () => {
        startFetchData()
        await getAll();
        finishFetchData();
    }

    const transferMultiNFTItem = async (files) => {
        startWork();
        console.log("files", files)
        await transferMultiNFT(files)
        finishWork();
    }

    const radioChangeHandler = (event)=> {
        setScreen(event.target.value)
    }
    console.log("nfts", nfts)
    console.log("tokenCollabs", tokenCollabs)
    console.log("tokens", tokens)
   const data = screen === SCREENS.MARKET? nfts: screen === SCREENS.TOKEN ? tokens: tokenCollabs
    return (
        <LandingPageLayout>
            <AppContainer className="my-8">
                <div>
                    <div className="text-xl"> Transfer {screen}</div>
                    <div className="flex flex-wrap justify-start gap-4">
                        {
                            Object.keys(SCREENS).map((key, index)=> {
                                return (
                                    <RadioButton
                                        changed={radioChangeHandler }
                                        id={index}
                                        isSelected={ screen === SCREENS[key] }
                                        label={SCREENS[key]}
                                        value={SCREENS[key]}
                                    />
                                )
                            })
                        }
                    </div>
                    {screen === SCREENS.MULTIMARKET ? <CreateMultiMarketItem isWorking={isWorking} title={isWorking? "Transfering N Items": "Transfer N Items"} execute={transferMultiNFTItem}/> : <TransferTable data={data} headers={ownerHeaders} loading={fetchingData} screen={screen}/>}
                </div>
            </AppContainer>
        </LandingPageLayout>
    );
};

export default Transfer;

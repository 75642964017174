import { Link } from "react-router-dom";
import Image from "../Image";
import Sell from "./Sell";
import Redeem from './Redeem';
import Liquidate from './Liquidate';

const ProductCard = ({ product, index, me = false }) => {
    return (
        <>
            <Link className={me ? "cursor-default" : "cursor-pointer"} key={index} to={`/marketplace/${product.id}`}>
                <div className="min-h-[100px] flex flex-col justify-between">
                    <Image
                        className="w-full object-contain"
                        src={product.image}
                        alt={product.name}
                    />
                    <div className="text-center opacity-50 text-sm">{product.name}</div>
                    {/* <div className="text-center text-sm text-[#82C9F7]">
                        {
                            me ? <Redeem redeemed={product.redeemed} item={{ item: product.normalData, id: product.id, price: product.price }} />
                                : <div></div>
                        }
                    </div>
                    <div className="text-center text-sm text-[#82C9F7]">
                        {
                            me ? <Liquidate redeemed={product.redeemed} item={{ item: product.normalData, id: product.id, price: product.price }} />
                                : <div></div>
                        }
                    </div> */}
                </div>
            </Link>
        </>
    );
};

export default ProductCard;

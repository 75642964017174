import React, { useState } from "react";
import { useWorkingIndicator } from "./useBooleanToggler"
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { create as ipfsHttpClient } from "ipfs-http-client";
import axios from "axios";
import { nftAddress, stateAddress, nft1155Adress, rpcEndpoint, newRpcEndpoint, GRAPH_URL, nftCollabAddress, tstToken } from "../config";
import NFT from "../artifacts/contracts/Nft.sol/NFT.json";
import NFTState from '../artifacts/contracts/NFTState.sol/NFTState.json';
import TSToken from "../artifacts/contracts/TSToken.sol/TST.json";
import Token from "../artifacts/contracts/NFT1155.sol/NFT1155.json";
import TokenCollab from "../artifacts/contracts/NFT1155Collab.sol/NFT1155Collab.json";
import { createClient } from 'urql';
import { Buffer } from 'buffer';
// const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');

const projectId = "2DAKc2jkKVEQagih9xQxIZxb6y5";
const projectSecret = "1eed35c39713b0b6c3453f17db7faf1c";
const auth = 'Basic ' + Buffer.from(projectId + ":" + projectSecret).toString('base64');

const client = ipfsHttpClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth
    }
});

const graphClient = createClient({
    url: GRAPH_URL
});
const useMarket = () => {
    const { isWorking, startWork, finishWork } = useWorkingIndicator();
    const [data, setData] = useState([]);
    const [myNftsData, setMyNftsData] = useState([]);
    const [nftDetail, setNftDetail] = useState({});
    const [isBuying, setBuying] = useState(false)
    const [tokens, setTokens] = useState([]);
    const [tokenCollabs, setTokenCollab] = useState([]);
    const [isTransfer, setTransfer] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const APISecret = "AL198989jha28281";

    const mint = async ({ price, ...rest }) => {
        startWork()
        const data = JSON.stringify({ ...rest });
        try {
            const added = await client.add(data);
            const url = `https://alwayslegit.infura-ipfs.io/ipfs/${added.path}`;
            await createSale(url, price);

        } catch (error) {
            console.log("error: ", error)
        }
    };

    const generateURI = async ({ price, ...rest }) => {
        startWork()
        const data = JSON.stringify({ ...rest });
        try {
            const added = await client.add(data);
            const uri = `https://alwayslegit.infura-ipfs.io/ipfs/${added.path}`;
            console.log(uri);

        } catch (error) {
            console.log("error: ", error)
        }
    };

    const createSale = async (url, priceValue) => {
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            const price = ethers.utils.parseUnits(priceValue, 'ether')
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let transaction = await contract.createToken(price, url)
            let tx = await transaction.wait();
            let event = tx.events[0];
            let value = event.args[2];
            let tokenId = value.toNumber();
            console.log("tokenId", tokenId)

            const token = await contract.getShoe(tokenId);
            const tokenListingId = token.listingId;

            // let url = "https://www.alwayslegit.com/listing/token?secret_key=" + APISecret + "&listing_id=" + tokenListingId + "&token_id=" + tokenId + "&token_address=" + nftAddress;
            // console.log('url: ');
            // console.log(url);

            // axios({
            //     method: 'get',
            //     url: url,
            // }).then(function (response) {
            //     console.log(response);
            // }).catch((error) => {
            //     console.error(error);
            // });

            // await fetchOnSales()
            finishWork()
        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
            finishWork()
        }

    }

    const mintMultiple = async (jsonString = []) => {
        try {
            const listingIds = [];
            const tokenUris = [];

            let nfts = JSON.parse(jsonString);

            for (let i = 0; i < nfts.length; i++) {
                let item = nfts[i];
                let listingId = item.listingId;
                let uri = item.uri;

                listingIds.push(listingId)
                tokenUris.push(uri);
            }

            await createMultipleSale(listingIds, tokenUris)
        }
        catch (err) {
            console.error(err);
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
        }
    }

    const createMultipleSale = async (listingIds = [], tokenUris = []) => {
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer)

            let transaction = await contract.createMultipleTokens(listingIds, tokenUris)
            await transaction.wait();

            console.log('listing ids');
            console.log(listingIds);

            let listingIdsInt = [];
            for (let i = 0; i < listingIds.length; i++) {
                let num = parseInt(listingIds[i]);
                listingIdsInt.push(num);
            }

            console.log('listng ids int');
            console.log(listingIdsInt);

            //keyword

            let stateContract = new ethers.Contract(stateAddress, NFTState.abi, signer)

            console.log('getting shoe ids')
            let tokenIds = await stateContract.getShoeIdsFromMultipleListingIds(listingIdsInt);

            console.log('token ids (BIG INT');
            console.log(tokenIds);

            let tokenIdsStrings = [];
            let tokenAddresses = [];
            for (let i = 0; i < tokenIds.length; i++) {
                let tid = tokenIds[i];
                let tidstring = tid.toString();
                tokenIdsStrings.push(tidstring);
                tokenAddresses.push(nftAddress)
            }

            console.log("tokenIDS");
            console.log(tokenIdsStrings);

            const tokenIdParams = tokenIdsStrings.join(',');
            const tokenAddressParams = tokenAddresses.join(',');
            const listingIdParams = listingIds.join(',');


            // const params = {
            //     secret_key: APISecret,
            //     listing_id: listingIdParams,
            //     token_id: tokenIdParams,
            //     token_address: tokenAddressParams
            // };

            // console.log('params');
            // console.log(params);

            // const headers = {
            //     'content-type': 'application/json'
            // };

            // axios.post('https://www.alwayslegit.com/listing/token', params, headers)
            //     .then(function (response) {
            //         console.log(response);
            //     }).catch((error) => {
            //         console.error(error);
            //     });

            const params = new URLSearchParams();
            params.append('secret_key', APISecret);
            params.append('listing_id', listingIdParams);
            params.append('token_id', tokenIdParams);
            params.append('token_address', tokenAddressParams);

            axios.post('https://www.alwayslegit.com/listing/token', params)
                .then(function (response) {
                    console.log(response)
                }).catch((error) => {
                    console.error(error);
                })



        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
        }
    }


    const uploadFileToIPFS = async (file) => {
        console.log("file: " + file)
        try {
            const added = await client.add(
                file,
                {
                    progress: (prog) => console.log(`received: ${prog}`)
                }
            )
            const url = `https://alwayslegit.infura-ipfs.io/ipfs/${added.path}`
            return url
        } catch (error) {
            console.log('Error uploading file: ', error)
            return null
        }
    }






    const relistNFT = async (itemId, price) => {
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer)

            const transaction = await contract.setTokenEnable(itemId, itemId, price)
            await transaction.wait();
            // await findItem(itemId);
            window.alert("Resold Item")
        } catch (err) {
            console.log(err)
            if (err.data && err.data.message) {
                window.alert("Failed to buy RELIST NFT: " + err.data.message)
            }
        }
    }

    const cancelListing = async (itemId) => {
        console.log('cancel listing')
    }

    const redeemShoe = async (itemId) => {
        setBuying(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer);

            const transaction = await contract.redeem(itemId)
            await transaction.wait();
            await findItem(itemId);

            const token = await contract.getShoe(itemId);
            const tokenListingId = token.listingId;

            let url = "https://www.alwayslegit.com/listing/burn?secret_key=" + APISecret + "&listing_id=" + tokenListingId + "&burn=yes";
            console.log('url: ');
            console.log(url);

            axios({
                method: 'get',
                url: url,
            }).then(function (response) {
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });

            window.alert("Redeemed Item")
        } catch (err) {
            console.log(err.data)
            if (err.data && err.data.message) {
                window.alert("Failed to redeem item: " + err.data.message)
            }
        }
        setBuying(false)
    }

    const liquidateShoe = async (itemId) => {
        setBuying(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer);

            const transaction = await contract.liquidate(itemId)
            await transaction.wait();
            await findItem(itemId);

            const token = await contract.getShoe(itemId);
            const tokenListingId = token.listingId;

            let url = "https://www.alwayslegit.com/listing/liquidate?secret_key=" + APISecret + "&listing_id=" + tokenListingId + "&liquidate=yes";
            console.log('url: ');
            console.log(url);

            axios({
                method: 'get',
                url: url,
            }).then(function (response) {
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });

            window.alert("Item liquidated")
        } catch (err) {
            console.log(err.data)
            if (err.data && err.data.message) {
                window.alert("Failed to liquidate item: " + err.data.message)
            }
        }
        setBuying(false)
    }

    const buyNFT = async (itemId, priceItem) => {
        console.log("buyNFT");
    }

    const waitAllowance = async (
        contract,
        account,
        to,
        allowanceNeeded,
        timesLeft
    ) => {
        if (timesLeft > 1) {
            const currentAllowance = await contract.allowance(account, to)

            console.log(`I want ${allowanceNeeded}, and current is ${currentAllowance} `)
            const needed = ethers.BigNumber(allowanceNeeded).toNumber()
            const current = ethers.BigNumber(currentAllowance.toString()).toNumber()
            if (current.isGreaterThanOrEqualTo(needed)) {
                return
            }
            await new Promise((res) => setTimeout(res, 1000))
            await waitAllowance(contract, account, to, allowanceNeeded, timesLeft - 1)
        }
        throw new Error('wait allowance failed for many times.')
    }

    const transferToken = async (itemId, addresses) => {
        console.log("transferToken: ", itemId, addresses)
        setTransfer(true)
        let contract = null
        try {
            console.log(ethers.utils)
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            contract = new ethers.Contract(nftCollabAddress, Token.abi, signer)
            const arrayAd = addresses.replace(/ /g, '').split(",")
            const validAddres = [];
            for (let i = 0; i < arrayAd.length; i++) {
                let valid = ethers.utils.getAddress(arrayAd[i]);
                validAddres.push(valid);
            }
            const transaction = await contract.transferBatch(itemId, validAddres)
            await transaction.wait();
            await fetchTokens();
        } catch (err) {
            console.log(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer Token: " + err.data.message + contract.address)
            }
        }
        setTransfer(false)
    }

    const transferTokenCollab = async (itemId, addresses) => {
        console.log("transferToken: ", itemId, addresses)
        setTransfer(true)
        try {
            console.log(ethers.utils)
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)
            const arrayAd = addresses.replace(/ /g, '').split(",")
            const validAddres = [];
            for (let i = 0; i < arrayAd.length; i++) {
                let valid = ethers.utils.getAddress(arrayAd[i]);
                validAddres.push(valid);
            }
            const transaction = await contract.transferBatch(itemId, validAddres)
            await transaction.wait();
            await fetchTokens();
        } catch (err) {
            console.log(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer Token: " + err.data.message)
            }
        }
        setTransfer(false)
    }


    const transferNFT = async (itemId, address) => {
        setTransfer(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftAddress, NFT.abi, signer)
            const transaction = await contract.transferShoe(itemId, address)
            await transaction.wait();
            //  await fetchOnSales();
        } catch (err) {
            console.error(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer NFT: " + err.data.message)
            }
        }
        setTransfer(false)
    }

    const transferMultiToken = async (tokenId, jsonString = []) => {
        try {
            let nfts = JSON.parse(jsonString);
            const recipients = [];
            for (let i = 0; i < nfts.length; i++) {
                let item = nfts[i];
                let recipient = item.address;
                recipients.push(ethers.utils.getAddress(recipient))
            }

            console.log("recipients", recipients)

            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nft1155Adress, Token.abi, signer)
            const transaction = await contract.transferBatch(tokenId, recipients)
            await transaction.wait();
        } catch (err) {
            console.error(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer NFT: " + err.data.message)
            }
        }
    }

    const transferMultiTokenCollab = async (tokenId, jsonString = []) => {
        try {
            let nfts = JSON.parse(jsonString);
            const recipients = [];

            for (let i = 0; i < nfts.length; i++) {
                let item = nfts[i];
                console.log("ITEM", item)
                let recipient = item.address;

                recipients.push(ethers.utils.getAddress(recipient))
            }

            console.log("recipients", recipients)

            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)
            const transaction = await contract.transferBatch(tokenId, recipients)
            await transaction.wait();
        } catch (err) {
            console.error(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer NFT: " + err.data.message)
            }
        }
    }

    const transferMultiNFT = async (jsonString = []) => {
        let nfts = JSON.parse(jsonString);
        try {
            const tokenIDs = [];
            const recipients = [];

            for (let i = 0; i < nfts.length; i++) {
                let item = nfts[i];
                let tokenID = item.tokenID;
                let recipient = item.recipient;

                tokenIDs.push(tokenID);
                recipients.push(ethers.utils.getAddress(recipient))
            }

            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftAddress, NFT.abi, signer)

            // keyword
            const transaction = await contract.transferMultipleShoes(tokenIDs, recipients)
            await transaction.wait();

            console.log('getting shoe listing ids');
            let stateContract = new ethers.Contract(stateAddress, NFTState.abi, signer)
            let shoes = await stateContract.getMultipleShoes(tokenIDs);

            console.log('shoes');
            console.log(shoes);

            let listingIds = [];
            for (let i = 0; i < shoes.length; i++) {
                let currentShoe = shoes[i];
                let lid = currentShoe.listingId.toString()
                listingIds.push(lid);
            }

            const listingIdParams = listingIds.join(',');
            const recipientParams = recipients.join(',');

            const params = new URLSearchParams();
            params.append('secret_key', APISecret);
            params.append('listing_id', listingIdParams);
            params.append('owner_address', recipientParams);

            axios.post('https://www.alwayslegit.com/listing/ownerAddress', params)
                .then(function (response) {
                    console.log(response)
                }).catch((error) => {
                    console.error(error);
                })

        } catch (err) {
            console.error(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer NFT: " + err.data.message)
            }
        }
    }


    const fetchAvailableTokensCollab = async () => {
        const provider = new ethers.providers.JsonRpcProvider(newRpcEndpoint)
        const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
        const data = await tokenContract.fetchAvailablesTokens();
        console.log('return data');
        console.log(data);
        return data;
    }

    const getUriCollab = async (tokenId) => {
        const provider = new ethers.providers.JsonRpcProvider(newRpcEndpoint)
        const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
        const tokenUri = await tokenContract.uri(tokenId)
        console.log('return uri');
        console.log(tokenUri);
        return tokenUri;
    }

    const getMetaCollab = async (tokenUri) => {
        const meta = await axios.get(tokenUri);
        console.log('meta');
        console.log(meta);
        return meta;
    }

    const fetchAvailableTokens1155 = async () => {
        const provider = new ethers.providers.JsonRpcProvider(newRpcEndpoint)
        const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
        const data = await tokenContract.fetchAvailablesTokens();
        console.log('return data');
        console.log(data);
        return data;
    }

    const getUri1155 = async (tokenId) => {
        const provider = new ethers.providers.JsonRpcProvider(newRpcEndpoint)
        const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
        const tokenUri = await tokenContract.uri(tokenId)
        console.log('return uri');
        console.log(tokenUri);
        return tokenUri;
    }

    const getMeta1155 = async (tokenUri) => {
        const meta = await axios.get(tokenUri);
        console.log('meta');
        console.log(meta);
        return meta;
    }

    const fetchTokens = async () => {

        try {
            const provider = new ethers.providers.JsonRpcProvider(newRpcEndpoint)
            const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
            const data = await fetchAvailableTokens1155();

            let items = []

            //i = 1 to ignore first token
            for (let i = 1; i < data.length; i++) {
                let cdata = data[i];
                let tokenId = parseInt(cdata.tokenId);
                const tokenUri = await getUri1155(tokenId);
                const meta = await getMeta1155(tokenUri);
                let item = {
                    price: meta.data.price,
                    itemId: cdata.tokenId.toNumber(),
                    quantity: cdata.amount.toNumber(),
                    file: meta.data.file,
                    name: meta.data.name,
                    image: meta.data.image,
                }
                items.push(item);
            }

            setTokens(items)
        } catch (err) {
            console.log(err)
            setTokens([])
            window.alert("Failed fetch tokens: " + err.data.message)
        }

    }

    const fetchTokensCollab = async () => {

        try {
            const provider = new ethers.providers.JsonRpcProvider(newRpcEndpoint)
            const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
            const data = await fetchAvailableTokensCollab();

            let items = []

            //i = 1 to ignore first token
            for (let i = 1; i < data.length; i++) {
                let cdata = data[i];
                let tokenId = parseInt(cdata.tokenId);
                const tokenUri = await getUriCollab(tokenId);
                const meta = await getMetaCollab(tokenUri);
                let item = {
                    price: meta.data.price,
                    itemId: cdata.tokenId.toNumber(),
                    quantity: cdata.amount.toNumber(),
                    file: meta.data.file,
                    name: meta.data.name,
                    image: meta.data.image,
                }
                items.push(item);
            }
            setTokenCollab(items)
        } catch (err) {
            console.log(err)
            setTokens([])
            window.alert("Failed fetch tokens COLLAB: " + err.data.message)
        }

    }


    const fetchData = async (tokensQuery) => {
        let res = await graphClient.query(tokensQuery).toPromise();
        console.log('res data');
        console.log(res.data);
        if (res.data.tokens) {
            let response = await Promise.all(res.data.tokens.map(async token => {
                console.log("token: ", token);
                const metadataURI = token.contentURI || ""
                // console.log("data: " + JSON.stringify(token.token.contentURI))
                let meta = {};
                try {
                    console.log("metadataURI: ", metadataURI)
                    const metaData = await fetch(metadataURI)
                    console.log("metaData: ", metaData)
                    // token.price = ethers.utils.formatUnits(token.price.toString(), 'ether')
                    let response = await metaData.json() || {}
                    meta = response
                } catch (err) {
                }
                return { ...token, ...meta }
            }))
            return response
        } else {
            let token = res.data.token
            const metadataURI = token.contentURI || "";
            // console.log("data: " + JSON.stringify(token.token.contentURI));
            let meta = {};
            try {
                // token.price = token.price.toString() //ethers.utils.formatUnits(token.price.toString(), 'ether')
                const metaData = await fetch(metadataURI)
                let response = await metaData.json() || {}
                meta = response
            } catch (err) {
                console.log(err)
            }
            return { ...token, ...meta }
        }
    }





    const findItem = async (id = 1) => {
        const query = `
        query {
            token(id: ${id}) {
                id
                contentURI
                redeemed
                owner {
                    id
                }
            }
        }`
        startWork()
        const nftDetailItem = await fetchData(query);
        console.log("nftDetailItem", { ...nftDetailItem, ...nftDetailItem.normalData })
        setNftDetail({ ...nftDetailItem, ...nftDetailItem.normalData })
        finishWork();
    }

    const fetchOnSales = async () => {
        startWork()
        const salesData = await fetchSalesNFTS()
        // const onSale = await fetchMyNFTS(nftMarket, true)
        // console.log("onSale", onSale)
        setData(salesData)
        finishWork();
    }

    const getAll = async () => {
        setLoading(true)
        await fetchTokensCollab();
        await fetchTokens();
        finishWork();
        setLoading(false)
    }

    const myNFTS = async (address) => {
        startWork()
        const myNFTS = await fetchMyNFTS(address)
        setMyNftsData(myNFTS)
        finishWork();
    }

    const fetchSalesNFTS = async (address, lowered = true) => {
        const web3Modal = new Web3Modal()
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        /* next, create the item */
        let contract = new ethers.Contract(nftAddress, NFT.abi, signer);
        // let contractOwner = await contract.owner();

        let holderAddress = await contract.getTokenHolder();

        const tokensQuery = `
            query {
                account(id: "${holderAddress.toLowerCase()}"){
                    items(where:{redeemed:false}) {
                        id
                        contentURI
                        listingId
                        redeemed
                    }
                }
            }
        `

        let res = await graphClient.query(tokensQuery).toPromise();

        let data = res.data;
        console.log('data');
        console.log(data);
        if (res.data.account.items) {
            console.log(res.data)

            console.log(res.data.account.items)
            const items = res.data.account.items
            let response = await Promise.all(items.map(async token => {
                const metadataURI = token.contentURI || ""
                let meta = {};
                try {
                    // token.price = ethers.utils.formatUnits(token.price.toString(), 'ether')
                    const metaData = await fetch(metadataURI)
                    let response = await metaData.json() || {}
                    meta = response
                } catch (err) {
                }
                return { ...token, ...meta }
            }))
            return response
        }
        else {
            return []
        }
    }

    const fetchMyNFTS = async (address, lowered = true) => {
        const tokensQuery = `
            query {
                account(id: "${lowered ? address.toLowerCase() : address}"){
                    items { 
                        id
                        contentURI
                        redeemed
                    }
                }
            }
        `
        let res = await graphClient.query(tokensQuery).toPromise();
        let data = res.data;
        if (res.data.account) {
            const items = res.data.account.items
            let response = await Promise.all(items.map(async token => {
                const metadataURI = token.contentURI || ""
                let meta = {};
                try {
                    // token.price = ethers.utils.formatUnits(token.price.toString(), 'ether')
                    const metaData = await fetch(metadataURI)
                    let response = await metaData.json() || {}
                    meta = response
                } catch (err) {
                }
                return { ...token, ...meta }
            }))
            return response

        }
        else {
            return []
        }
    }

    const mintToken = async ({ quantity, ...rest }) => {
        startWork()
        const data = JSON.stringify({ ...rest, quantity });
        console.log("data", data)
        try {
            const added = await client.add(data);
            console.log("added: ", added)
            const url = `https://alwayslegit.infura-ipfs.io/ipfs/${added.path}`;
            await createSaleToken(url, quantity);

        } catch (error) {
            console.log("error: ", error)
        }
    };

    const createSaleToken = async (url, quantity) => {
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nft1155Adress, Token.abi, signer)
            let transaction = await contract.mint(quantity, url)
            console.log("transaction: ", transaction)
            let tx = await transaction.wait()
            let event = tx.events[0]
            let value = event.args[2]
            let tokenId = value.toNumber()
            window.alert("Created successfully")
            console.log("tokenId: ", tokenId);
            finishWork()
        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
            finishWork()
        }

    }


    const mintTokenCollab = async ({ quantity, ...rest }) => {
        console.log("mintToken: ", rest)
        startWork()
        const data = JSON.stringify({ ...rest, quantity });
        console.log("data", data)
        try {
            const added = await client.add(data);
            const url = `https://alwayslegit.infura-ipfs.io/ipfs/${added.path}`;
            await createSaleTokenCollab(url, quantity);

        } catch (error) {
            console.log("error: ", error)
        }
    };

    const createSaleTokenCollab = async (url, quantity) => {
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)
            let transaction = await contract.mint(quantity, url)
            let tx = await transaction.wait()
            let event = tx.events[0]
            let value = event.args[2]
            let tokenId = value.toNumber()
            window.alert("Created successfully")
            console.log("tokenId: ", tokenId);
            finishWork()
        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
            finishWork()
        }

    }




    return {
        mint, relistNFT, cancelListing, mintMultiple, transferMultiNFT, transferMultiTokenCollab, transferMultiToken,
        buyNFT,
        generateURI,
        uploadFileToIPFS,
        isWorking,
        fetchOnSales,
        nfts: data,
        fetchData,
        findItem,
        nftDetail,
        redeemShoe,
        liquidateShoe,
        myNFTS, myNftsData, isBuying, mintToken, mintTokenCollab, fetchTokens, tokens, tokenCollabs, transferNFT, isTransfer, transferToken, getAll, isLoading, transferTokenCollab, fetchTokensCollab
    };
};

export default useMarket;

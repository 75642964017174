import React from 'react'
import Spinner from "../Spinner";

const Button = ({title, onClick = () => {}, loading = false})=> {
    return (
        <button onClick={onClick} disabled={loading}
            className="bg-transparent flex gap-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            {title}{loading && <Spinner/>}
        </button>
    )
}

export default Button

import React,{ createContext } from 'react';
import useToken from "../hooks/useToken"

export const TokenContext = createContext(null);

const TokenProvider = ({children}) =>{
    const {
        uploadFileToIPFS,
        isWorking, mintToken, fetchTokens, tokens, isTransfer, transferToken, getAll, isLoading} = useToken();


    return (
        <TokenContext.Provider value={{
            uploadFileToIPFS,
            isWorking, mintToken, fetchTokens, tokens, isTransfer, transferToken, getAll, isLoading}}>
            {children}
        </TokenContext.Provider>
    );
}

export default TokenProvider;

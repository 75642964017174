import React from "react";
import styles from "./spinner.module.scss";

const Spinner = ({ size = 20, black=true }) => (
    <div
        className={black? styles.spinner: styles.spinner1}
        style={{
            width: `${size}px`,
            height: `${size}px`
        }}
    />
);

export default Spinner;

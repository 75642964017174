import React from 'react'

const Select = ({id="role", data = {}, values=[{value: "", label: "Choose a Role", defaultValue: true},
    {value: "Admin", label: "Admin", defaultValue: false}, {value: "Minter", label: "Minter", defaultValue: false}], onChange=()=> {}})=> {

    return (
        <>
            <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Small
                select</label>
            <select id={id} value={data} onChange={onChange}
                    className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                {
                    values.map((value) =>(
                        <option defaultValue={value.defaultValue} value={value.value}>{value.label}</option>
                    ))
                }
            </select>
        </>
    )
}

export default Select

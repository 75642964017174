import AppContainer from "../AppContainer";
import ImageIcon, { ICONS } from "../ImageIcon";
import LinkApp from "../LinkApp";

const links = [
  { name: "MAIN SITE", link: "https://www.alwayslegit.com" },
  { name: "TERMS", link: "https://www.alwayslegit.com/page/terms" },
  { name: "PRIVACY", link: "https://www.alwayslegit.com/page/policy" },
  { name: "CONTACT US", link: 'https://design-2022.alwayslegit.com/contact' }
];

const socialMedia = [
];

const getCurrentYear = () => new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="py-10">
      <AppContainer
        className="flex flex-col items-center md:flex-row md:justify-between gap-5 flex-wrap
      "
      >
        <div className="flex items-center gap-8">
          {links.map((link) => (
            <LinkApp
              key={link.name}
              href={link.link}
              className="opacity-50 hover:opacity-60 text-lg"
              type="externalLink"
            >
              {link.name}
            </LinkApp>
          ))}
        </div>

        <div className="flex items-center gap-6">
          {socialMedia.map((link) => (
            <LinkApp
              key={link.name}
              href={link.link}
              className="opacity-50 hover:opacity-60 h-7 w-7"
              type="externalLink"
            >
              <ImageIcon nameIcon={link.icon} />
            </LinkApp>
          ))}
        </div>

        <div className="opacity-50 text-lg">
          © {getCurrentYear()} ALWAYSLEGIT INC.

        </div>

        <div className="opacity-50 text-lg">
          Past performance is not an indicator of future results.
          All trademarks are property of their respective owners

        </div>


      </AppContainer>
    </footer>
  );
};

export default Footer;

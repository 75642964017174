import React, { useState } from "react";
import { useWorkingIndicator } from "./useBooleanToggler"
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { nftMarket, nftAddress, GRAPH_URL, nft1155Adress, nftCollabAddress } from "../config";
import NFT from "../artifacts/contracts/Nft.sol/NFT.json";
import Market from "../artifacts/contracts/Marketplace.sol/NFTMarket.json";
import Token from "../artifacts/contracts/NFT1155.sol/NFT1155.json";
import TokenCollab from "../artifacts/contracts/NFT1155Collab.sol/NFT1155Collab.json";
import { createClient } from 'urql';

const graphClient = createClient({
    url: GRAPH_URL
});
const useOwner = () => {
    const { isWorking, startWork, finishWork } = useWorkingIndicator();
    const [accounts, setAccounts] = useState([]);
    const [myAccount, setMyAccount] = useState(null);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);


    const addMinter = async (address) => {
        console.log("addMinter", address)
        setIsSaving(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            //  const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer);
            // const token = new ethers.Contract(nft1155Adress, Token.abi, signer)
            // const tokenCollab = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)


            // const addedToken = await tokenContract.addMinter(address)
            // await addedToken.wait();

            const addedMarket = await contract.addMultipleMinter(address, nftCollabAddress, nft1155Adress)
            await addedMarket.wait();

            // const addedTokenD = await token.addMinter(address)
            // await addedTokenD.wait();
            //
            // const addedTokenCollab = await tokenCollab.addMinter(address)
            // await addedTokenCollab.wait();

            await fetchRoles()

            console.log(`addedToken minter: ${addedMarket}, addedMarket minter: ${addedMarket}`)
        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to create ROLE: " + err.data.message);
                return null;
            }
        }
        setIsSaving(false)
    }

    const addAdmin = async (address) => {
        setIsSaving(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            // const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer);
            // const token = new ethers.Contract(nft1155Adress, Token.abi, signer)
            // const tokenCollab = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)

            // const addedToken = await tokenContract.addAdmin(address)
            // await addedToken.wait();

            const addedMarket = await contract.addMultipleAdmin(address, nftCollabAddress, nft1155Adress)
            await addedMarket.wait();

            // const addedTokenD = await token.addAdmin(address)
            // await addedTokenD.wait();
            //
            // const addedTokenCollab = await tokenCollab.addAdmin(address)
            // await addedTokenCollab.wait();

            await fetchRoles();
            console.log(`addedToken admin: ${addedMarket}, addedMarket admin: ${addedMarket}`)
        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to create ROLE: " + err.data.message);
                return null;
            }
        }

        setIsSaving(false)
    }

    const removeMinter = async (address) => {
        setIsRemoving(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            // const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer);
            // const token = new ethers.Contract(nft1155Adress, Token.abi, signer)
            // const tokenCollab = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)


            // const addedToken = await tokenContract.removeMinter(address)
            // await addedToken.wait();

            const addedMarket = await contract.removeMultipleMinter(address, nftCollabAddress, nft1155Adress)
            await addedMarket.wait();

            // const addedTokenD = await token.removeMinter(address)
            // await addedTokenD.wait();
            //
            // const addedTokenCollab = await tokenCollab.removeMinter(address)
            // await addedTokenCollab.wait();

            console.log(`removeToken minter: ${addedMarket}, removeMarket minter: ${addedMarket}`)

        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to remove role minter: " + err.data.message);
                return null;
            }
        }

        setIsRemoving(false)
    }


    const removeAdmin = async (address) => {
        setIsRemoving(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            //  const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer);
            // const token = new ethers.Contract(nft1155Adress, Token.abi, signer)
            // const tokenCollab = new ethers.Contract(nftCollabAddress, TokenCollab.abi, signer)

            const addedToken = await contract.removeMultipleAdmins(address)
            await addedToken.wait();

            // const addedMarket = await contract.removeAdmin(address)
            // await addedMarket.wait();
            //
            // const addedTokenD = await token.removeAdmin(address)
            // await addedTokenD.wait();
            //
            // const addedTokenCollab = await tokenCollab.removeAdmin(address)
            // await addedTokenCollab.wait();


            console.log(`removeToken admin: ${addedToken}, removeMarket admin: ${addedToken}`)

        } catch (err) {
            if (err.data && err.data.message) {
                window.alert("Failed to remove role admin: " + err.data.message);
                return null;
            }
        }

        setIsRemoving(false)
    }

    const remove = async (account) => {
        console.log(`remove ${account.id}`)
        const { id, role = "MINTER" } = account
        if (role.toLowerCase() === "minter") {
            await removeMinter(id)
            await fetchRoles()
        } else if (role.toLowerCase() === "admin") {
            await removeAdmin(id)
            await fetchRoles()
        }

    }






    const fetchMyRoles = async (tokensQuery) => {
        let res = await graphClient.query(tokensQuery).toPromise();
        if (res.data.accounts) {
            let response = await Promise.all(res.data.accounts.filter(async account => {
                return { ...account }
            }))

            return response

        } else {
            return res.data && res.data.account && res.data.account.role ? res.data.account : { role: "GUEST" };
        }

    }



    const fetchRoles = async () => {
        const rolesQuery = `
         {
       accounts {
      id
      role
     }
   }
`
        startWork()
        const roles = await fetchMyRoles(rolesQuery);
        setAccounts(roles)
        finishWork();
    }



    const getMyRole = async (address = "") => {
        const myAddress = address ? address.toLowerCase() : address;
        const rolesQuery = `
         query {
         account(id: "${myAddress}"){
         role
      }
  }
`

        startWork()
        const roles = await fetchMyRoles(rolesQuery) || {};
        setMyAccount({ ...roles, address })
        finishWork();
    }



    return {
        isWorking,
        addMinter,
        remove,
        addAdmin,
        fetchRoles,
        accounts,
        myAccount, setMyAccount,
        getMyRole, isRemoving, isSaving
    };
};

export default useOwner;

import React from 'react';
import Input from "../Base/Input";
import TextArea from "../Base/TextArea";
import File from "../Base/File";
import Button from "../Base/Button";
import useForm from "../../hooks/useFormV2.js";
import {useWorkingIndicator} from "../../hooks/useBooleanToggler"
import {posterFileType, fileType} from "../../config";

const TokenForm = ({token=true})=> {
    const {isWorking: isUploadingPoster, startWork: startUploadPoster, finishWork: finishUploadPoster} = useWorkingIndicator();
    const {isWorking: isUploadingFile, startWork: startUploadFile, finishWork: finishUploadFile} = useWorkingIndicator();
    const {isWorking, startWork, finishWork} = useWorkingIndicator();

    const {data, handleChange, sendFile, onSubmit,
        sendPoster} = useForm({}, token);

     async function onChange(e) {
        startUploadFile()
        const file = e.target.files[0]
        await sendFile(file)
        finishUploadFile()
    }

    async function onChangePoster(e) {
        startUploadPoster()
        const file = e.target.files[0]
        await sendPoster(file)
        finishUploadPoster()
    }

    const createToken = async ()=> {
        startWork()
        await onSubmit()
        finishWork()
    }

    console.log("data: " + JSON.stringify(data))

    return (
        <div className="w-full max-w-lg">
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["name"]}
                           label="Name" name={"name"} type="text"
                           handleChange={handleChange} feedback={true}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3">
                    <Input value={data["price"]}
                           label="Price" name={"price"} type="text"
                           handleChange={handleChange}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3">
                    <Input value={data["quantity"]}
                           label="Quantity" name={"quantity"} type="text"
                           handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <TextArea value={data["description"]}
                              label="Description" name={"description"}
                              handleChange={handleChange} feedback={false}
                    />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <File accept={posterFileType} isWorking={isUploadingPoster}
                          label={isUploadingPoster? "Uploading Poster...": "Upload your Poster"} name={"image"} type="file"
                          handleChange={onChangePoster}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <File accept={fileType} isWorking={isUploadingFile}
                          label={isUploadingFile? "Uploading Video...": "Upload your File"} name={"file"} type="file"
                          handleChange={onChange}
                    />
                </div>
            </div>
            <div className="w-full px-3 flex content-between">
                <Button  title={isWorking ? "Minting..." : "Mint"} disabled={isWorking} onClick={createToken}/>
            </div>
        </div>
    )
}

export default TokenForm;

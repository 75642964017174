import React, {useContext, useState} from "react";
import LandingPageLayout from "../../components/LayoutMain";
import AppContainer from "../../components/AppContainer";
import {MarketForm, TokenForm, CreateMultiMarketItem} from "../../components/Forms";
import {SCREENS} from "../../config"
import RadioButton from "../../components/Base/RadioButton"
import {useWorkingIndicator} from "../../hooks/useBooleanToggler";
import {DataContext} from "../../providers/MainProvider";
const Create = () => {
    const {isWorking, startWork, finishWork} = useWorkingIndicator();
    const {mintMultiple} = useContext(DataContext);
    const [screen, setScreen] = useState(SCREENS.MARKET)

    const radioChangeHandler = (event)=> {
        setScreen(event.target.value)
    }

    const createMulti = async (file) => {
        if(!file) {
            alert("Please upload a Json")
        }
        startWork();
        console.log("file", file);
        await mintMultiple(file)
        finishWork()
    }

    return (
        <LandingPageLayout>
            <AppContainer className="my-8 flex justify-center">
               <div>
                   <div className="text-xl"> Create {screen}</div>
                   <div className="flex flex-wrap justify-start gap-6">
                       {
                           Object.keys(SCREENS).map((key, index)=> {
                               return (
                                   <RadioButton
                                       changed={radioChangeHandler }
                                       id={index}
                                       isSelected={ screen === SCREENS[key] }
                                       label={SCREENS[key]}
                                       value={SCREENS[key]}
                                   />
                               )
                           })
                       }
                   </div>
                   <div className="flex items-center">
                       <div className="flex">
                           {
                               screen === SCREENS.TOKEN? <TokenForm/>: screen === SCREENS.TOKEN_COLLAB? <TokenForm token={false} />: screen === SCREENS.MARKET?  <MarketForm/>
                                   : <CreateMultiMarketItem isWorking={isWorking} title={isWorking? "Creating N Items": "Create N Items"} execute={createMulti}/>

                           }

                       </div>
                   </div>
               </div>
            </AppContainer>
        </LandingPageLayout>
    );
};

export default Create;

import React, {useState}  from "react";
import {useWorkingIndicator} from "./useBooleanToggler"
import {ethers} from "ethers";
import Web3Modal from "web3modal";
import axios from "axios";
import {nft1155Adress, rpcEndpoint} from "../config";
import Token from "../artifacts/contracts/NFT1155.sol/NFT1155.json";
import {create as ipfsHttpClient} from "ipfs-http-client";
const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');
const useToken = () => {
    const {isWorking, startWork, finishWork} = useWorkingIndicator();
    const [tokens, setTokens] = useState([]);
    const [isTransfer, setTransfer] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const uploadFileToIPFS = async (file) => {
        console.log("file: " + file)
        try {
            const added = await client.add(
                file,
                {
                    progress: (prog) => console.log(`received: ${prog}`)
                }
            )
            const url = `https://ipfs.infura.io/ipfs/${added.path}`
            return url
        } catch (error) {
            console.log('Error uploading file: ', error)
            return null
        }
    }

    const transferToken = async (itemId, addresses) => {
        console.log("transferToken: ", itemId, addresses)
        setTransfer(true)
        try {
            console.log(ethers.utils)
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nft1155Adress, Token.abi, signer)
            const arrayAd= addresses.replace(/ /g,'').split(",")
            const validAddres = [];
            for(let i=0; i<arrayAd.length; i++){
                let valid=ethers.utils.getAddress(arrayAd[i]);
                validAddres.push(valid);
            }
            if(validAddres.length < 1){
                alert("Please upload a valid json");
                return
            }
            const transaction = await contract.transferBatch(itemId, validAddres)
            await transaction.wait();
            await fetchTokens();
        } catch (err){
            console.log(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer Token: " + err.data.message)
            }
        }
        setTransfer(false)
    }

    const fetchTokens = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint)
            const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
            const data = await tokenContract.fetchAvailablesTokens()

            const items = await Promise.all(data.map(async i => {
                console.log("i: ", i);
                const tokenUri = await tokenContract.uri(i.tokenId)
                const meta = await axios.get(tokenUri);
                let item = {
                    price: meta.data.price,
                    itemId: i.tokenId.toNumber(),
                    quantity: i.amount.toNumber(),
                    file: meta.data.file,
                    name: meta.data.name,
                    image: meta.data.image,
                }
                return item
            }));
            setTokens(items)
        } catch(err){
            console.log(err)
            setTokens([])
            window.alert("Failed fetch tokens: " + err.data.message)
        }

    }




    const getAll = async () => {
        setLoading(true)
        await fetchTokens();
        finishWork();
        setLoading(false)
    }



    const mintToken = async ({quantity, ...rest}) => {
        console.log("mintToken: ", quantity, rest)
        startWork()
        const data = JSON.stringify({...rest, quantity});
        console.log("data", data)
        try{
            const added = await client.add(data);
            const url =  `https://ipfs.infura.io/ipfs/${added.path}`;
            await createSaleToken(url, quantity);

        }catch(error){
            console.log("error: ", error)
        }
    };

    const createSaleToken = async (url, quantity) => {
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nft1155Adress, Token.abi, signer)
            let transaction = await contract.mint(quantity, url)
            let tx = await transaction.wait()
            let event = tx.events[0]
            let value = event.args[2]
           // let tokenId = value.toNumber()
            window.alert("Created successfully")
            console.log("tokenId: ", value);
            finishWork()
        } catch(err){
            console.log("   error: ", err)
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
            finishWork()
        }

    }

    return {
        uploadFileToIPFS,
        isWorking, mintToken, fetchTokens, tokens, isTransfer, transferToken, getAll, isLoading
    };
};

export default useToken;

import React, { useEffect, useState, useContext } from 'react';
import useBooleanToggler from "../../hooks/useBooleanToggler";
import AppContainer from "../AppContainer";
import DropdownButton from "../DropdownButton";
import Image from "../Image";
import ImageIcon, { ICONS } from "../ImageIcon";
import LinkApp from "../LinkApp";
import { useEthers } from "@usedapp/core";
 import {OwnerContext} from "../../providers/OwnerProvider";


const links = [
  // {
  //   name: "ROBO-ADVISOR",
  //   link: "/robo-advisor",
  // },
  // {
  //   name: "MARKETPLACE",
  //   link: "/marketplace",
  // },
  // {
  //   name: "SELL",
  //   link: "/sell",
  // },
  // {
  //   name: "",
  //   link: "/search",
  //   icon: ICONS.search,
  // },
];

const LinksHeaders = () => {

  return (
    <div className="hidden md:flex gap-4 lg:gap-7">
      {links.map((item) => (
        <LinkApp
          className="opacity-50 text-lg hover:opacity-60 hover:no-underline"
          key={item.id}
          href={item.link}
          type="link"
        >
          <div className="flex items-center gap-2">
            {item?.icon && <ImageIcon className="h-4 w-4" nameIcon={item.icon} />}
            {item.name}
          </div>
        </LinkApp>
      ))}
    </div>
  );
}


const Header = () => {
  const { isToggled, reToggle, unToggle } = useBooleanToggler();
  const { getMyRole, myAccount } = useContext(OwnerContext);

  const { activateBrowserWallet, account } = useEthers();

  useEffect(async () => {

    if (account) {
      await getMyRole(account)
    } else {
      activateBrowserWallet();
    }
  }, [account]);

  console.log("account", account)
  console.log("myAccount", myAccount)

  const handleWallet = () => {
    activateBrowserWallet();
  }

  // const toggleOpenMenu = () => setVisible(!isVisible);

  return (
    <header className="w-screen py-6 shadow-sm sticky top-0 bg-white z-10">
      <AppContainer className="flex items-center justify-between gap-10 w-full">
        <div className="flex items-center gap-4 lg:gap-7 flex-1">
          <LinkApp href={"/"}>
            <Image src={require("../../assets/images/logo.png")} />
          </LinkApp>
          <LinksHeaders />

        </div>

        <div>
          <button onClick={handleWallet}
            className="bg-black hover:bg-gray-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
            {!account ? 'Connect Wallet' : `${account.slice(0, 10)}...`}
          </button>
        </div>

        <DropdownButton
          isActive={isToggled}
          onOpen={isToggled ? unToggle : reToggle}
          onClose={unToggle}
          customContent={
            <div className="flex flex-col justify-center gap-y-5 max-w-sm w-48 divide-y">
              {
                myAccount && myAccount.address && <LinkApp
                  href={"/me"}
                  className="pt-5 opacity-50 text-lg hover:no-underline  hover:opacity-60"
                >
                  ACCOUNT
                    </LinkApp>
              }


              {
                myAccount && myAccount.role !== "GUEST" && <LinkApp
                  href={"/create"}
                  className="pt-5 opacity-50 text-lg hover:no-underline  hover:opacity-60"
                >
                  CREATE NFT
                    </LinkApp>
              }

              {
                myAccount && myAccount.role === "ADMIN" &&
                <LinkApp
                  href={"/transfer"}
                  className="pt-5 opacity-50 text-lg hover:no-underline  hover:opacity-60"
                >
                  TRANSFER
                    </LinkApp>
              }

              {
                myAccount && myAccount.role === "ADMIN" && <LinkApp
                  href={"/admin"}
                  className="pt-5 opacity-50 text-lg hover:no-underline  hover:opacity-60"
                >
                  ADMIN
                    </LinkApp>
              }
            </div>
          }
        >
          <div className="flex items-center gap-3 w-full">
            PROFILE
            <ImageIcon className="h-8 w-8" nameIcon={ICONS.userDefault} />
          </div>
        </DropdownButton>
      </AppContainer>
    </header>
  );
};

export default Header;

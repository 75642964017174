import Spinner from "../../components/Spinner"
const File = ({value="", name = "", type="file", label = "",accept, handleChange, hide= false, isWorking = false})=> {
    return (
        <>
            {!hide &&  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 flex gap-2"
                              htmlFor={name}>
                {`${label}`} {isWorking && <Spinner/>}
            </label>}
            <input accept={accept}
                className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id={name} value={value} type={type} onChange={(e)=> handleChange(e)}/>
        </>
    )
}

export default File

import { useState } from "react";
import CardPrimary from "../../components/CardPrimary";
const FilterShoes = ({ filter, onChangeFilter }) => {
  const [modelsOptions] = useState([
    { id: 1, name: "AIR JORDAN 1" },
    { id: 2, name: "AIR JORDAN 4" },
    { id: 3, name: "AIR FORCE 1" },
    { id: 4, name: "Chuck 70" },
    { id: 5, name: "Descents" },
    { id: 1, name: "AIR JORDAN 1" },
    { id: 2, name: "AIR JORDAN 4" },
    { id: 3, name: "AIR FORCE 1" },
    { id: 4, name: "Chuck 70" },
    { id: 5, name: "Descents" },
  ]);

  const [menSizesOptions] = useState([
    { id: 1, name: "6", price: "" },
    { id: 2, name: "6.5", price: "" },
    { id: 3, name: "7", price: "" },
    { id: 4, name: "7.5", price: "" },
    { id: 5, name: "8", price: "" },
    { id: 6, name: "9", price: "" },
    { id: 7, name: "9.5", price: "" },
    { id: 8, name: "10", price: "" },
    { id: 9, name: "11", price: "" },
    { id: 10, name: "11.5", price: "" },
    { id: 11, name: "12", price: "" },
  ]);

  const [womanSizesOptions] = useState([
    { id: 12, name: "6", price: "" },
    { id: 13, name: "6.5", price: "" },
    { id: 14, name: "7", price: "" },
    { id: 15, name: "7.5", price: "" },
    { id: 16, name: "8", price: "" },
    { id: 17, name: "9", price: "" },
    { id: 18, name: "9.5", price: "" },
  ]);

  const [kidSizesOptions] = useState([
    { id: 19, name: "6", price: "" },
    { id: 20, name: "6.5", price: "" },
    { id: 21, name: "7", price: "" },
  ]);

  /**
   * It takes in an itemSize and returns a boolean value based on whether or not the itemSize exists in
   * the filter.menSizes array
   * @param itemSize - the size of the item that is being added to the list
   */
  const addSizeToList = (itemSize, key = "menSizes") => {
    try {
      const existItem = Boolean(
        filter[key].find(({ id }) => itemSize?.id === id)
      );

      if (existItem) {
        onChangeFilter({
          [key]: filter[key].filter(({ id }) => itemSize?.id !== id),
        });
      } else {
        onChangeFilter({
          [key]: [...filter[key], itemSize],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isSiseIsSelected = (itemSize, array) => {
    if (array?.length > 0) {
      return Boolean(array.find(({ id }) => itemSize?.id === id));
    }
    return false;
  };

  return (
    <div>
      {/* models */}
      <div className="mt-9">MODELS</div>
      <div className="h-60 overflow-y-auto flex flex-col gap-5 mt-3">
        {modelsOptions.map((item, index) => (
          <div
            onClick={() => addSizeToList(item, "models")}
            className="text-lg opacity-50 hover:opacity-75 flex gap-2 items-center cursor-pointer"
            key={index}
          >
            <CardPrimary
              onClick={() => addSizeToList(item, "models")}
              className="h-[20px] w-[20px] rounded-[5px]"
              borderActive={isSiseIsSelected(item, filter?.models)}
              key={index}
            />
            {item.name}
          </div>
        ))}
      </div>

      {/* man */}
      <div className="mt-9">MEN SIZES</div>
      <div className="flex gap-2 flex-wrap mt-3">
        {menSizesOptions.map((item, index) => (
          <CardPrimary
            onClick={() => addSizeToList(item, "menSizes")}
            className="h-[70px] w-[70px] text-lg"
            borderActive={isSiseIsSelected(item, filter?.menSizes)}
            key={index}
          >
            {item.name}
          </CardPrimary>
        ))}
      </div>

      {/* woman */}
      <div className="mt-9">WOMAN SIZES</div>
      <div className="flex gap-2 flex-wrap mt-3">
        {womanSizesOptions.map((item, index) => (
          <CardPrimary
            onClick={() => addSizeToList(item, "womanSizes")}
            className="h-[70px] w-[70px] text-lg"
            borderActive={isSiseIsSelected(item, filter?.womanSizes)}
            key={index}
          >
            {item.name}
          </CardPrimary>
        ))}
      </div>

      {/* boy */}
      <div className="mt-9">KID SIZES</div>
      <div className="flex gap-2 flex-wrap mt-3">
        {kidSizesOptions.map((item, index) => (
          <CardPrimary
            onClick={() => addSizeToList(item, "kidSizes")}
            className="h-[70px] w-[70px] text-lg"
            borderActive={isSiseIsSelected(item, filter?.kidSizes)}
            key={index}
          >
            {item.name}
          </CardPrimary>
        ))}
      </div>
    </div>
  );
};

export default FilterShoes;

import ProductCard from "../Product";
import ProductCardMe from "../Product/Product-Me";

const CardList = ({ products = [], isFilterActive = false, me = false }) => {
  return (
    <div
      className={`grid gap-4 ${
        isFilterActive
          ? "grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
          : "sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
        }`}
    >
      {products.map((product, index) => (
        <ProductCard index={index} product={product} me={me} />
      ))}
    </div>
  );
};

export default CardList;

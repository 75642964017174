import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "../../components/AppContainer";
import ButtonPrimary from "../../components/ButtonPrimary";
import CardPrimary from "../../components/CardPrimary";
import LandingPageLayout from "../../components/LayoutMain";
import SwiperImages from "../../components/swiper";
import { useWorkingIndicator } from "../../hooks/useBooleanToggler";
import { DataContext } from "../../providers/MainProvider";
import { OwnerContext } from "../../providers/OwnerProvider";

const DetailProduct = () => {
  const { idProduct } = useParams();
  const navigate = useNavigate();
  const {
    findItem,
    nftDetail: product,
    liquidateShoe,
    redeemShoe
  } = useContext(DataContext);

  const { myAccount = { address: '' }, getMyRole } = useContext(OwnerContext);


  const { isWorking, startWork, finishWork } = useWorkingIndicator({
    initialValue: true,
  });

  const { isWorking: isBuying, startWork: startBuying, finishWork: finishBuying } = useWorkingIndicator();


  const redeem = async (itemId) => {
    startWork();
    await redeemShoe(itemId);
    finishWork();
  }

  const liquidate = async (itemId) => {
    startBuying()
    await liquidateShoe(itemId);
    finishBuying()
  }



  /**
   * It takes the user to the marketplace page
   */
  const redirectToMarketPlace = () => navigate("/marketplace");

  useEffect(async () => {
    if (!myAccount && !myAccount.address) {
      await getMyRole(myAccount)
    }
  }, [myAccount]);

  const handlerGetProduct = async () => {
    startWork();
    await findItem(idProduct);
    finishWork();
  };

  useEffect(async () => {
    if (idProduct) await handlerGetProduct();
    else {
      redirectToMarketPlace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("myAccount", myAccount)

  const redirect = () => {
    window.location.replace('https://www.alwayslegit.com/');
  }

  const generateDetailButtons = () => {
    console.log('product');
    console.log(product);
    const addressItem = myAccount && myAccount.address ? myAccount.address.toLowerCase() : ""
    let element;
    if (product.owner.id.toLowerCase() === addressItem) {

      if (product.redeemed) {
        element = (
          <div>
            <div>
              <ButtonPrimary
                className="mt-10"
                title={"Redeemed"}
              />

            </div>
            <div>
              <ButtonPrimary onClick={() => redirect()} className="mt-10" title={"Cancel"} />
            </div>
          </div>

        )
      } else {
        element = (
          <div>
            <div>
              <ButtonPrimary loading={isWorking} onClick={() => redeem(product.id)} className="mt-10" title={isWorking ? "Redeeming" : "Redeem"} />

            </div>
            <div>
              <ButtonPrimary loading={isBuying} onClick={() => liquidate(product.id)} className="mt-10" title={isBuying ? "Liquidating" : "Liquidate"} />
            </div>
            <div>
              <ButtonPrimary onClick={() => redirect()} className="mt-10" title={"Cancel"} />
            </div>
          </div>
        )
      }
    }


    return element;
  }

  return (
    <LandingPageLayout>
      <AppContainer className="my-5 lg:my-20">
        {isWorking ? (
          <p>Loading...</p>
        ) : (
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-7">
              <SwiperImages images={[product?.image] || []} />
            </div>

            <div className="col-span-12 even:lg:col-span-5 pt-12 lg:pl-32 lg:pt-0">
              <div className="text-4xl">{product.name || ""}</div>
              <div className="text-4xl">{product.manufacturer}</div>
              <div className="text-4xl">{"listing ID: " + product.listingId}</div>
              <div className="mt-5 flex gap-2 flex-wrap">
                <CardPrimary
                  className="h-[70px] w-[70px] text-lg"
                  borderActive={true}>
                  {product.size}
                </CardPrimary>
              </div>

              {generateDetailButtons()}

            </div>
          </div>
        )}
      </AppContainer>
    </LandingPageLayout>
  );
};

export default DetailProduct;

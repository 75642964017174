import React, {useState} from 'react';
import {Button} from "../../Base"
const ownerHeaders = [
    "#", "Address", "Role", "Remove"
]

const DataTable = ({removeRole = ()=> {}, headers = ownerHeaders, data = [], loading = false, removing = false})=> {
    const [selectedItem, setSelected] = useState(null);



   const  removeItem = (item)=> {
       console.log("item", item)
       setSelected(item.id || "")
       removeRole(item);
   }

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 white:bg-gray-700 dark:text-gray-400">
                <tr>
                    {
                        headers.map((header)=>(
                            <>
                                    <th scope="col" className="px-6 py-3 text-black">
                                        {header}
                                    </th>
                            </>
                        ))
                    }
                </tr>
                </thead>
                {
                    loading? <div>Loading...</div>:
                        <tbody>
                        {
                            data.map((item, index)=>(
                                <tr className="bg-white border-b white:bg-gray-800 white:border-gray-900 hover:bg-gray-50 white:hover:bg-gray-900">
                                    <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                        {index}
                                    </th>
                                    <td className="px-6 py-4 text-black">
                                        {item.id}
                                    </td>
                                    <td className="px-6 py-4 text-black">
                                        {item.role}
                                    </td>
                                    <td className="px-6 py-4 text-right flex justify-center">
                                        <Button loading={(removing && item.id === selectedItem)} onClick={()=> removeItem(item)} title={removing && item.id === selectedItem? "Removing role": "Remove role"}/>
                                    </td>
                                </tr>

                            ))
                        }


                        </tbody>
                }
            </table>
        </div>
    )
}


export default DataTable

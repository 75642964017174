import React, { useState } from 'react';
import Input from "../Base/Input";
import TextArea from "../Base/TextArea";
import File from "../Base/File";
import Button from "../Base/Button";
import useForm from "../../hooks/useForm";
import { useWorkingIndicator } from "../../hooks/useBooleanToggler"
import { posterFileType, fileType } from "../../config";

const MarketForm = () => {
    const { data, handleChange, sendFile, onSubmit, onGenerateURI,
        sendPoster, uploadUnlockableContent } = useForm({});
    const { isWorking: isUploadingPoster, startWork: startUploadPoster, finishWork: finishUploadPoster } = useWorkingIndicator();
    const { isWorking: isUploadingFile, startWork: startUploadFile, finishWork: finishUploadFile } = useWorkingIndicator();
    const { isWorking, startWork, finishWork } = useWorkingIndicator();
    const [unlockable, setUnlokable] = useState([1]);

    const setNewUnlockable = () => {
        setUnlokable([...unlockable, unlockable.length + 1])
    }

    // console.log(data)

    async function onChange(e) {
        startUploadFile()
        const file = e.target.files[0]
        await sendFile(file)
        finishUploadFile();
    }

    async function onChangePoster(e) {
        startUploadPoster();
        const file = e.target.files[0]
        await sendPoster(file)
        finishUploadPoster();
    }

    const uploadUnlockableContentImages = async (e) => {
        const file = e.target.files[0]
        console.log("file: ", file)
        await uploadUnlockableContent(file)
    }

    const createNft = async () => {
        startWork()
        await onSubmit()
        finishWork()
    }

    const generateTokenURI = async () => {
        startWork()
        await onGenerateURI()
        finishWork()
    }

    // console.log("data: " + JSON.stringify(data))

    return (
        <div className="w-full max-w-lg">
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["name"]}
                        label="Name" name={"name"} type="text"
                        handleChange={handleChange}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["listingId"]}
                        label="Listing ID" name={"listingId"} type="text"
                        handleChange={handleChange}
                    />
                </div>

                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["size"]}
                        label="Size" name={"size"} type="text"
                        handleChange={handleChange}
                    />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <Input value={data["styleId"]}
                        label="Style Id" name={"styleId"} type="text"
                        handleChange={handleChange} feedback={false}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <TextArea value={data["description"]}
                        label="Description" name={"description"}
                        handleChange={handleChange} feedback={false}
                    />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["manufacturer"]}
                        label="Manufacturer" name={"manufacturer"} type="text"
                        handleChange={handleChange} feedback={false}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["nickName"]}
                        label="NickName" name={"nickName"} type="text"
                        handleChange={handleChange} feedback={false}
                    />
                </div>

                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <Input value={data["colorWay"]}
                        label="ColorWay" name={"colorWay"} type="text"
                        handleChange={handleChange} feedback={false}
                    />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <File accept={posterFileType} isWorking={isUploadingPoster}
                        label={isUploadingPoster ? "Uploading Poster..." : "Upload your Poster"} name={"poster"} type="file"
                        handleChange={onChangePoster}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <File accept={fileType} isWorking={isUploadingFile}
                        label={isUploadingFile ? "Uploading Video..." : "Upload your File"} name={"file"} type="file"
                        handleChange={onChange}
                    />
                </div>

            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-1/2 px-3">
                    {unlockable.map((value) => {
                        return (
                            <File accept={`${posterFileType},${fileType}`}
                                label={`Upload your Unlockable content ${value}`} name={`unlock`} type="file"
                                handleChange={uploadUnlockableContentImages}
                            />)
                    })}
                </div>
                <div className="w-1/2 px-3">
                    <Button title="More unlockable" onClick={() => setNewUnlockable()} />
                </div>
            </div>
            <div className="w-full px-3 flex content-between">
                <Button title={isWorking ? "Minting..." : "Mint"} disabled={isWorking} onClick={createNft} />
            </div>
            <div className="w-full px-3 flex content-between">
                <Button title={isWorking ? "Generating..." : "Generate URI"} disabled={isWorking} onClick={generateTokenURI} />
            </div>
        </div>
    )
}

export default MarketForm;


const fields = ['animation_url', 'name', 'listingId', 'size', "description", "styleId", "manufacturer", "nickName", "colorWay"]

export const  hasRequiredKeys = (object) => {
  return Object.keys(object).filter(key => fields.includes(key)).length === fields.length
}

const fieldsItems = ['name','price', 'description', 'quantity', "animation_url"]

export const  hasRequiredKeysToken = (object) => {
  return Object.keys(object).filter(key => fieldsItems.includes(key)).length === fieldsItems.length
}

export const socialMedia = [
  {
    name: 'Facebook',
    icon: 'fa-facebook-f',
    url: 'https://www.facebook.com/',
    className: 'sr-facebook'
  },
  {
    name: 'Instagram',
    icon: 'fa-envelope',
    url: 'https://www.instagram.com/',
    className: 'sr-instagram'
  },
  {
    name: 'Twitter',
    icon: 'fa-twitter',
    url: 'sr-twitter',
    className: 'sr-twitter'
  }
]

export const buildOpenSeaMetadata = (data) => {
  return {
    name: data.name,
    description: data.description,
    image:data.image,
    animation_url:data.animation_url,
   // price: data.price,
    normalData: data,
    attributes:[
      {
        trait_type: "styleId",
        value: data.styleId
      },
      {
        trait_type: "listingId",
        value: data.listingId
      },
      {
        trait_type:"manufacturer",
        value: data.manufacturer
      },
      {
        trait_type:"nickName",
        value: data.nickName
      },
      {
        trait_type:"colorWay",
        value: data.colorWay
      },
      {
        trait_type:"model",
        value:data.model
      },
      {
        trait_type:"size",
        value: data.size
      }
    ],
    unLockable:data.unLockable
  }

}



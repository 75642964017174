import React, { createContext } from 'react';
import useMarket from "../hooks/useMarket.js"
import OwnerProvider from "./OwnerProvider"
import TokenCollabProvider from "./TokenCollabProvider"
import TokenProvider from "./TokenProvider"

export const DataContext = createContext(null);

const MainProvider = ({ children }) => {
    const {
        mint, resellItem, relistNFT, mintMultiple, transferMultiNFT, generateURI,
        buyNFT, uploadFileToIPFS, isWorking, tokenCollabs, nfts, fetchOnSales,
        fetchMyNFT, fetchData, fetchTokensCollab, createSaleTokenCollab, findItem,
        cancelListing, nftDetail, myNFTS, myNftsData, isBuying, mintToken,
        mintTokenCollab, fetchTokens, tokens, transferNFT, isTransfer,
        transferToken, getAll, isLoading, transferTokenCollab, redeemShoe,
        liquidateShoe, transferMultiTokenCollab,transferMultiToken } = useMarket();





    return (
        <DataContext.Provider value={{
            transferMultiNFT, mint, generateURI, relistNFT, mintMultiple, buyNFT, mintTokenCollab, tokenCollabs, fetchTokensCollab, createSaleTokenCollab, uploadFileToIPFS, isWorking, nfts, fetchOnSales, fetchMyNFT,
            fetchData, findItem, transferTokenCollab, resellItem, redeemShoe,
            nftDetail, cancelListing, myNFTS, myNftsData, isBuying, mintToken,
            fetchTokens, tokens, transferNFT, isTransfer, transferToken, getAll,
            isLoading, liquidateShoe, transferMultiTokenCollab,transferMultiToken
        }}>
            <OwnerProvider>
                <TokenCollabProvider>
                    <TokenProvider>
                        {children}
                    </TokenProvider>
                </TokenCollabProvider>
            </OwnerProvider>
        </DataContext.Provider>
    );
}

export default MainProvider;

import React, { useContext, useEffect } from "react";
import LandingPageLayout from "../../components/LayoutMain";
import AppContainer from "../../components/AppContainer";
import  {
    useWorkingIndicator,
} from "../../hooks/useBooleanToggler";
import CardList from "../../components/CardList";
import { DataContext } from "../../providers/MainProvider";
import { OwnerContext } from "../../providers/OwnerProvider";


const Me = () => {
    const { myNftsData: products, myNFTS } = useContext(DataContext);
    const { myAccount } = useContext(OwnerContext);

    const { isWorking, startWork, finishWork } = useWorkingIndicator({
        initialValue: false,
    });


    useEffect(async () => {
        const loadData = async () => {
            // TODO: fetch data from API
            startWork();
            if (myAccount) {
                await myNFTS(myAccount.address);
                // await getMyRole();
            }
            console.log("myNftsData", products)
            finishWork();
        };

        await loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myAccount]);



    if(products && products.length > 0 && !products[0].image){
        window.location.reload();
    }

    return (
        <LandingPageLayout>
            <AppContainer className="my-8">
                <div className="flex gap-14">
                    <div className="flex-1 transition-all duration-500 mt-1">
                        {isWorking && (
                            <div className="text-center opacity-50 text-lg">Loading...</div>
                        )}

                        <CardList products={products} isFilterActive={false} me={true} />
                    </div>
                </div>
            </AppContainer>
        </LandingPageLayout>
    );
};

export default Me;

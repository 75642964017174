import React, {useContext, useState} from 'react';
import {Button, Image, File, Input} from "../../../components/Base"
import {SCREENS} from "../../../config";
import {DataContext} from "../../../providers/MainProvider";
import {useWorkingIndicator} from "../../../hooks/useBooleanToggler";


const BodyTable = ({item, screen = SCREENS.TOKEN}) => {
    const {transferNFT, transferMultiTokenCollab,transferMultiToken} = useContext(DataContext);
    const [myNft, setNft] = useState(null);
    const [files, setFiles] = useState("");
    const {isWorking: isTransferringData, startWork: startTransferData, finishWork: finishTransferData} = useWorkingIndicator();

    async function onChange(e) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setFiles(e.target.result);
        };
    }

    const transferItem = async ({itemId, id, ...rest}) => {
        setNft(itemId);
        if(files.length < 0){
            alert("Please add an Address");
            return;
        } else {
            startTransferData()
            if(screen === SCREENS.TOKEN){
                await transferMultiToken(itemId, files)
            } else if(screen === SCREENS.TOKEN_COLLAB){
                await transferMultiTokenCollab(itemId, files)
            }
            else if(screen === SCREENS.MARKET){
                await transferNFT(id, files)
            } else {
                return;
            }
            finishTransferData()
        }
    }

    return (
        <tr className="bg-white border-b white:bg-gray-800 white:border-gray-900 hover:bg-gray-50 white:hover:bg-gray-900">
            <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap">
                {item.name}
            </th>
            <td className="px-6 py-4 text-black">
                {
                    screen === SCREENS.MARKET?
                        <div className="w-[120px] md:w-1/2">
                            <Input value={files} hide={true} feedback={false} label="Address" name={"files"} type="text"
                                   handleChange={(e)=>{ setFiles(e.target.value)}}/>
                        </div> :
                        <div className="w-[120px] md:w-1/2">
                            <File accept={"application/json"} hide={true}
                                  label={"Upload your Json"} name={"file"} type="file"
                                  handleChange={onChange}
                            />
                        </div>
                }
            </td>
            <td className="px-6 py-4 text-black">
                <Image uri={item.image} />
            </td>
            <td className="px-6 py-4 text-black">
                {item.quantity || 1}
            </td>
            <td className="px-6 py-4 text-right flex justify-center">
                <Button onClick={()=>transferItem(item)} loading={(isTransferringData && myNft === item.itemId)} title={isTransferringData && myNft === item.itemId? "Transferring...":"Transfer"}/>
            </td>
        </tr>
    )
}


export default BodyTable


const RadioButton = ({id, label, changed, value, isSelected})=> {
    return (
        <>
            <input onChange={changed} checked id="default-radio-2" type="radio" value={value} name={id} checked={isSelected}
                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600
                   dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor={id} className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{label}</label>
        </>
    )
}

export default RadioButton

import Spinner from "./Spinner";
import React from "react";

const ButtonPrimary = ({
  title = "click me!",
  onClick = () => {},
  className = "", loading,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`w-full h-[70px] px-4 bg-black text-white text-md rounded-lg truncate ${className} `}
      onClick={onClick}
    >
      <div className="flex justify-center gap-2">
        {title} {loading && <Spinner black={false}/>}
      </div>
    </button>
  );
};

export default ButtonPrimary;
